import { downloadFile, uploadFile, post, get } from '../request';
import { Iresponse } from '../interface';


/**
 * 小文件和图片上传
 * @param params 文件对象
 * @param replenishData axios进度条
 * @returns 调取上传的axios请求实例
 */
export const FileUpload = (params: any, replenishData: any): Promise<Iresponse> => {
  return uploadFile({ url: '/system/w/uploadFile', timeout: 0, params, replenishData });
};
// 文件上传MD5
export const AdminCheckMd5 = (params?: any): Promise<Iresponse> => {
  return post({ url: '/system/w/checkMd5', params, ContentType: 1 });
};
// 大文件->断点续传
export const AdminUploadBigFile = (params: any, replenishData: any): Promise<Iresponse> => {
  return uploadFile({ url: '/system/w/uploadBigFile', timeout: 0, params, replenishData });
};

/**
 * 文件下载
 * @param path 文件路径
 */
export const FileDownload = (path: string, name?: string) => {
  downloadFile({ url: '/system/w/downloadByPath', params: { path } }).then((res: any) => {
    if (res.code === '000') {
      fileDownloadAfter(res.result, name);
    }
  });
};

export const FileDownload2 = (path: string, params: any, name?: string) => {
  downloadFile({ url: path, params: params }).then((res: any) => {
    if (res.code === '000') {
      fileDownloadAfter(res.result, name);
    }
  });
};

/**
 * 导出xlsx
 * @param url 导出xlsx文件的接口路径
 * @param fileName xlsx文件的名称
 */
export const DownloadXlsx = (url: string, params: any, fileName?: string): void => {
  get({ url: url, params: params, fileName: fileName }).then((res) => {
    if (res.code === '000') {
      const date = new Date();
      const year = date.getFullYear();
      const month = formatNumber(date.getMonth() + 1);
      const day = formatNumber(date.getDate());
      function formatNumber(n: any): string {
        n = n.toString();
        return n[1] ? n : '0' + n;
      }
      fileDownloadAfter(res, `${fileName}--${year}-${month}-${day}.xlsx`);
    }
  });
};

function fileDownloadAfter(res: any, name?: string) {
  let fileName: any = '';
  const blob = new Blob([res.data]);
  if (name) {
    fileName = name;
  } else {
    fileName = decodeURIComponent(res.headers['content-disposition'].split(';')[1].split('filename=')[1]);
  }
  if ('download' in document.createElement('a')) {
    // 非IE下载
    const elink: any = document.createElement('a');
    elink.download = fileName;
    elink.style.display = 'none';
    elink.href = URL.createObjectURL(blob);
    document.body.appendChild(elink);
    elink.click();
    URL.revokeObjectURL(elink.href); // 释放URL 对象
    document.body.removeChild(elink);
  } else {
    // IE10+下载
    const n: any = window.navigator;
    n.msSaveBlob(blob, fileName);
  }
}
